const useProtocol = () => {
  const { $api } = useNuxtApp()

  async function setProtocol(protocolId, body) {
    const options = {
      method: 'PATCH',
      body
    }

    await $api(`/api/protocol/${protocolId}/`, options)
  }

  async function setAlerts(protocolId, val) {
    const { getSession } = useAuth()
    try {
      await setProtocol(protocolId, { alerts: val})
      getSession()
      return true
    } catch (error) {
      console.log("🚀 ~ useProtocol.js ~ setAlerts ~ error:", error)
      return false
    }
  }

  async function setService(protocolId, service) {
    const { getSession } = useAuth()
    try {
      await setProtocol(protocolId, { service })
      getSession()
      return true
    } catch (error) {
      console.log("🚀 ~ useProtocol.js ~ setService ~ error:", error)
      return false
    }
  }

  async function setQuote(quoteId, body) {
    try {
      const options = {
        method: 'PATCH',
        body
      }

      await $api(`/api/quote/${quoteId}/`, options)

      useAuth().getSession()
      return true
    } catch (error) {
      if (error.data) {
        return error.data.user_link[0]
      } else {
        console.log("🚀 ~ useProtocol ~ setQuote ~ error:", error.value)
        return false
      }
    }
  }

  return {
    setAlerts,
    setService,
    setQuote
  }
}

export default useProtocol